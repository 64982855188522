<template>
  <section class="container mx-auto py-48 px-4">
    <h2 class="text-3xl text-gray-900 leading-tight mb-2 font-extrabold">
      Artigos sobre Proteção de Dados.
    </h2>
    <p class="text-lg text-gray-600 mb-20">
      Se atualize sobre noticias relacionadas a segurança digital e proteção da
      sua empresa.
    </p>
    <div class="w-full">
      <div class="flex flex-col space-y-16">
        <div
          class="py-8 flex flex-wrap md:flex-no-wrap"
          v-for="(item, index) in posts"
        >
          <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
            <img
              :src="
                'https://cms.easytermos.com.br' +
                item.foto_destaque.formats.large.url
              "
              class="pr-5 object-cover w-full h-40 col-span-1 bg-center rounded"
              :alt="item.seo_url"
              loading="lazy"
            />
            <p
              class="pl-5 tracking-widest font-medium title-font text-gray-900"
            >
              {{ item.categoria.Nome }}
            </p>
            <span class="mt-1 text-gray-500 text-sm">{{ item.data }}</span>
          </div>
          <div class="md:flex-grow">
            <h2 class="text-2xl font-medium text-gray-900 title-font mb-2">
              {{ item.title }}
            </h2>
            <p class="leading-relaxed">
              <vue-showdown
                class="blog-post"
                :markdown="item.post_text.slice(0, 200) + '…'"
                flavor="github"
                :options="{ emoji: true }"
              />
            </p>
            <a
              class="text-indigo-500 inline-flex items-center mt-4"
              :href="'/artigos/' + item.seo_url"
              >Continue Lendo
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'BlogListET',
  data() {
    return {
      home: false,
      blogPosts: [],
    };
  },
  created() {
    this.showPosts();
  },
  computed: {
    ...mapState('blog', ['posts']),
  },
  methods: {
    ...mapActions('blog', ['getPosts']),
    async showPosts() {
      await this.getPosts({ sistema: 'EasyTermos', count: 0 });
    },
  },
};
</script>

<style scoped></style>
