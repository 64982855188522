<template>
  <div v-if="home === 'easyTermos'">
    <BlogListET></BlogListET>
  </div>
  <div v-else-if="home === 'complianceOnline'">
    <BlogListCO></BlogListCO>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';
import BlogListET from '../../components/Blog/BlogListET';
import BlogListCO from '../../components/Blog/BlogListCO';

export default {
  name: 'BlogCO',
  components: {
    BlogListCO,
    BlogListET,
  },
  data() {
    return {
      home: false,
      blogPosts: [],
    };
  },
  created() {
    this.showPosts();
    const url = document.URL;

    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';
  },
  computed: {
    ...mapState('blog', ['posts']),
  },
  methods: {
    ...mapActions('blog', ['getPosts']),
    async showPosts() {
      await this.getPosts({ sistema: 'EasyTermos', count: 0 });
    },
  },
  metaInfo() {
    let title;
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
      title = 'Compliance Online';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
      title = 'Easy Termos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    return {
      title: title,
      meta: [
        {
          name: 'description',
          content: 'Termos de uso e privacidade para adequação na LGPD.',
        },
      ],
    };
  },
};
</script>

<style scoped></style>
