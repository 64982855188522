<template>
  <section class="container mx-auto py-12 px-4">
    <h2 class="text-3xl text-gray-900 leading-tight mb-2 font-extrabold">
      Artigos sobre LGPD
    </h2>
    <p class="text-lg text-gray-600 mb-20">
      Confira nossos artigos, guias e anúncios para se manter atualizado com
      todas as leis e regulamentações de privacidade que afetam seus negócios.
    </p>
    <div class="w-full">
      <div class="flex flex-col space-y-16">
        <div
          class="grid grid-cols-1 gap-6 md:grid-cols-4"
          v-for="(item, index) in posts"
          v-bind:key="index"
        >
          <img
            :src="
              'https://cms.easytermos.com.br' +
              item.foto_destaque.formats.large.url
            "
            class="object-cover w-full h-40 col-span-1 bg-center"
            :alt="item.seo_url"
            loading="lazy"
          />
          <div class="col-span-1 md:col-span-3">
            <p class="mb-2 -mt-1 text-sm font-normal text-gray-500">
              {{ item.data }}
            </p>
            <h2 class="mb-2 text-xl font-extrabold leading-snug text-gray-800">
              <a
                :href="'/artigos/' + item.seo_url"
                class="text-gray-900 hover:text-purple-700"
                >{{ item.title }}</a
              >
            </h2>
            <p class="mb-3 text-sm font-normal text-gray-500">
              <vue-showdown
                class="blog-post"
                :markdown="item.post_text.slice(0, 200) + '…'"
                flavor="github"
                :options="{ emoji: true }"
              />
            </p>
            <a :href="'/artigos/' + item.seo_url" class="btn btn-light btn-sm"
              >Leia mais</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'BlogListCO',
  data() {
    return {
      home: false,
      blogPosts: [],
    };
  },
  created() {
    this.showPosts();
  },
  computed: {
    ...mapState('blog', ['posts']),
  },
  methods: {
    ...mapActions('blog', ['getPosts']),
    async showPosts() {
      await this.getPosts({ sistema: 'ComplianceOnline', count: 0 });
    },
  },
};
</script>

<style scoped></style>
